<template>
  <basic-block :title="currentTerm.termName">
    <template #search>
      <el-row :gutter="12">
        <el-col :span="6">
          <el-date-picker
            v-model="date"
            :size="$publicConfig.selectSize"
            type="daterange"
            style="width: 100%"
            range-separator="至"
            start-placeholder="开始日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy.MM.dd"
            @change="
              (e) => {
                search.dateBegin = e[0]
                search.dateEnd = e[1]
                getTableData()
              }
            "
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.campusCode"
            placeholder="请选择校区"
            @change="allSchool"
          >
            <el-option
              v-for="item in allschool"
              :key="item.campusCode"
              :label="item.campusName"
              :value="item.campusCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.gradeId"
            placeholder="请选择年级"
            @change="getgrade"
          >
            <el-option
              v-for="item in allGrade"
              :key="item.gradeCode"
              :label="item.grade"
              :value="item.gradeCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            v-model="search.classId"
            :size="$publicConfig.selectSize"
            placeholder="请选择班级"
            @change="getTableData"
          >
            <el-option
              v-for="item in allClass"
              :key="item.classCode"
              :label="item.className"
              :value="item.classCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.courseId"
            placeholder="请选择课程"
            filterable
            clearable
            @change="getTableData"
          >
            <el-option
              v-for="item in allSubjectList"
              :key="item.courseId"
              :label="item.courseName"
              :value="item.courseId"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="search.teacherName"
            :size="$publicConfig.selectSize"
            placeholder="输入老师名称搜索"
            prefix-icon="el-icon-search"
            @keyup.enter.native="getTableData"
            @blur="getTableData"
          >
          </el-input>
        </el-col>
      </el-row>
    </template>
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column prop="teacherName" label="姓名"></el-table-column>
      <el-table-column prop="name" label="考勤率">
        <template slot-scope="scope">
          {{
            scope.row.planTimes > 0
              ? (
                  ((scope.row.planTimes - scope.row.nonArriveTimes) /
                    scope.row.planTimes) *
                  100
                ).toFixed(2) + '%'
              : '0.00%'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="实考勤次数">
        <template slot-scope="scope">
          {{ scope.row.planTimes - scope.row.nonArriveTimes }}
        </template>
      </el-table-column>
      <el-table-column prop="planTimes" label="应考勤次数"></el-table-column>
      <el-table-column prop="onTimeTimes" label="准时"></el-table-column>
      <el-table-column prop="lateTimes" label="迟到"></el-table-column>
      <el-table-column prop="leaveTimes" label="请假"></el-table-column>
      <el-table-column prop="nonArriveTimes" label="缺勤"></el-table-column>
      <el-table-column prop="name" label="操作">
        <template slot-scope="scope">
          <el-link type="primary" @click="goto(scope.row)">查看详情</el-link>
        </template>
      </el-table-column>
    </el-table>
  </basic-block>
</template>

<script>
import { mapGetters } from 'vuex'
import { teacherRecordList } from '@/http/analysis'
import { getSubjectList } from '@/http/daping'
import { getSchoolTerm } from '@/http/leaveSchool'

export default {
  data() {
    return {
      search: {
        gradeId: '',
        classId: '',
        dateBegin: this.$cftY().split(' ')[0].replaceAll('/', '.'),
        dateEnd: this.$cftY().split(' ')[0].replaceAll('/', '.'),
        courseId: '',
        teacherName: ''
      },
      date: [
        this.$cftY().split(' ')[0].replaceAll('/', '.'),
        this.$cftY().split(' ')[0].replaceAll('/', '.')
      ],
      allSubjectList: [
        {
          courseId: '',
          courseName: '全部课程'
        }
      ],
      tableData: [],
      currentTerm: {},
      allGrade: [
        {
          grade: '全部年级',
          gradeCode: ''
        }
      ],
      allClass: [
        {
          className: '全部班级',
          classCode: ''
        }
      ]
    }
  },
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(['userInfo', 'allNGrade', 'allNClass', 'token']),
    allschool() {
      return [
        {
          campusCode: '',
          campusName: '全校'
        },
        ...this.allNGrade
      ]
    }
    // allClass() {
    //   return [
    //     {
    //       className: "全部班级",
    //       classCode: "",
    //     },
    //     ...this.allNClass.filter(
    //       (item) => item.gradeInfo.gradeCode == this.search.gradeId
    //     ),
    //   ];
    // },
  },
  created() {
    let params = {
      schoolId: this.userInfo.tnId,
      termId: 0
    }
    getSchoolTerm(params).then((res) => {
      this.currentTerm = res.data
    })
    this.getSubjectList()
    this.getTableData()
  },
  methods: {
    goto(row) {
      this.$router.push({
        path: '/attendance/teacherStatisticDetail',
        query: { ...row, ...this.search }
      })
    },
    getSubjectList() {
      getSubjectList({ schoolId: this.userInfo.tnId }).then((res) => {
        this.allSubjectList = [...this.allSubjectList, ...res.data]
      })
    },

    getTableData() {
      this.search.schoolId = this.userInfo.tnId
      teacherRecordList(this.search).then((res) => {
        if (res.retCode == 1) {
          this.tableData = res.data
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    allSchool(e) {
      console.log(e)
      this.allGrade = []
      this.allGrade.push({
        grade: '全部年级',
        gradeCode: ''
      })
      this.allschool.forEach((item) => {
        if (e == item.campusCode) {
          item.gradeList.forEach((items) => {
            this.allGrade.push(items)
          })
        }
      })
      console.log(this.allGrade)
    },
    getgrade(e){
      this.allClass = []
      this.allClass.push({
        className: '全部年级',
        classCode: ''
      })
      this.allGrade.forEach((item) => {
        console.log(item)
        console.log(e)
        if (e == item.gradeCode) {
          item.classList.forEach((items) => {
            this.allClass.push(items)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>