import request from './request'

/**
 * 获取全校考勤统计
 */

export const getAllStatistic = (params) => {
  return request({
    url: '/weChat/user/getTokenValue',
    method: 'get',
    params,
  })
}

/**
 * 获取年级考勤统计
 */

 export const getGradeStatistic = (params) => {
    return request({
      url: '/weChat/user/getTokenValue',
      method: 'get',
      params,
    })
  }

/**
 * 获取各年级考勤统计
 */

 export const getEachGradeStatistic = (params) => {
    return request({
      url: '/weChat/user/getTokenValue',
      method: 'get',
      params,
    })
  }


/**
 * 班级维度数据统计
 */

 export const studentCheckRecordStatistics = (data) => {
  return request({
    url: `/awardcard/api/student-check-record-statistics`,
    method: 'post',
    data
  })
}

/**
 * 学生维度统计
 */

 export const studentCheckRecordStatisticsStudent = (data) => {
  return request({
    url: `/awardcard/api/student-check-record-statistics/student`,
    method: 'post',
    data
  })
}


/**
 * 学生维度统计
 */

 export const studentCheckRecordStatisticsStudentDetail = (data) => {
  return request({
    url: `/awardcard/api/student-check-record-statistics/student_detail`,
    method: 'post',
    data
  })
}

/**
 * 学生维度统计
 */

 export const studentCheckRecordStatisticsStudentList = (data) => {
  return request({
    url: `/awardcard/api/student-check-record-statistics/student_list`,
    method: 'post',
    data
  })
}

/**
 * 更新考勤
 */

 export const updateAttendance = (data) => {
  return request({
    url: `/awardcard/api/attendance/status`,
    method: 'post',
    data
  })
}

/**
 * 教师考勤统计列表
 */

 export const teacherRecordList = (data) => {
  return request({
    url: `/awardcard/api/teacher-check-record-statistics/list`,
    method: 'post',
    data
  })
}

/**
 * 教师考勤统计详情
 */

 export const teacherRecordOne = (data) => {
  return request({
    url: `/awardcard/api/teacher-check-record-statistics/one`,
    method: 'post',
    data
  })
}

/**
 * 课节维度统计
 */

 export const studentCheckRecordStatisticsClass = (data) => {
  return request({
    url: `/awardcard/api/student-check-record-statistics/class`,
    method: 'post',
    data
  })
}