import request from './request'
import requests from './requests'

/**
 * 班级统计
 */

export const cardStatClass = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/class`,
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 数量统计
 */

export const cardStatCount = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/count`,
    method: 'get',
    params: {
      schoolId
    }
  })
}


/**
 * 历史记录，只返回20条
 */

export const cardStatHistory = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/history`,
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 学生统计
 */

export const cardStatStudent = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/student`,
    method: 'get',
    params: {
      schoolId
    }
  })
}


/**
 * 教师每日发卡
 */

export const cardStatTeacher = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/teacher`,
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 教师每日发卡
 */

export const cardStatTrends = (schoolId) => {
  return request({
    url: `/awardcard/api/card_stat/trends`,
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 教师每日发卡
 */

export const allStudentStat = (schoolId) => {
  return requests({
    url: `/base/api/v1/school/getPersonNumList`,
    method: 'get',
    params: {
      schoolId
    }
  })
}

/**
 * 学生统计所有的
 */

export const cardStatStudentAll = (params) => {
  return request({
    url: `/awardcard/api/card_stat/student/all`,
    method: 'get',
    params
  })
}

/**
 * 教师统计所有的
 */

export const cardStatTeacherAll = (params) => {
  return request({
    url: `/awardcard/api/card_stat/teacher/all`,
    method: 'get',
    params
  })
}

//学生领卡历史
export const studentHistory = (data) => {
  return request({
    url: '/awardcard/api/card/student/history',
    method: 'post',
    data: data,
  });
}

//老师发卡历史
export const teacherHistory = (data) => {
  return request({
    url: `/awardcard/api/card/teacher/history`,
    method: 'post',
    data
  });
}

/**
 * 获取年级信息
 */

export const getGradeList = (params) => {
  return requests({
    url: `/base/getClassList`,
    method: 'get',
    params
  })
}

/**
 * 获取班级信息
 */

export const getClassList = (data) => {
  data.classType = 0
  return requests({
    url: `/base/class/getClassList`,
    method: 'post',
    data
  })
}
/**
 * 获取班级信息
 */

export const getSubjectList = (params) => {
  return requests({
    url: `/base/getSubjectList`,
    method: 'get',
    params
  })
}

//获取学生
export const getStudent = (data) => {
  return requests({ 
    url: `/base/getStudentList`,
    method: 'post',
    data
  });
}

//获取教学时间
export const getCourseTime = (params) => {
  return request({
    url: `/awardcard/api/attendance/courseTime`,
    method: 'get',
    params
  });
}
//

export const getAttendanceStat = (params) => {
  return request({
    url: `/awardcard/api/attendance/stat`,
    method: 'get',
    params
  });
}