import request from './request'
import requests from './requests'
/**
 * 获取奖卡列表
 */

export const getList = (data) => {
  return request({
    url: `/awardcard/api/after_school/stat`,
    method: 'post',
    data
  })
}

/**
 * 统计当天留校数量
 */

export const gettoDayCount = (params) => {
  return request({
    url: `/awardcard/api/after_school/stat/count`,
    method: 'get',
    params
  })
}

/**
 * 留校原因获取
 */

export const getReason = (params) => {
  return request({
    url: `/awardcard/api/after_school/reason`,
    method: 'get',
    params
  })
}

/**
 * 设置留校原因
 */

export const submitReason = (data) => {
  return request({
    url: `/awardcard/api/after_school/reason`,
    method: 'post',
    data
  })
}
//放学打卡记录查看
export const getAfterSchoolRecord = (data) => {
  return request({
    url: `/awardcard/api/attendance/after_school`,
    method: 'post',
    data
  })
}

/**
 * 留校原因获取
 */

export const getAttendanceLog = (params) => {
  return request({
    url: `/awardcard/api/attendance_log`,
    method: 'get',
    params
  })
}

/**
 * 获取学年学期
 */

export const getSchoolTerm = (params) => {
  return requests({
    url: `/base/getSchoolTerm`,
    method: 'get',
    params
  })
}


export const exportLeaveSchool = (data) => {
  return request({
    url: '/awardcard/api/after_school/exportStat',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
